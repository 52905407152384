.dark {
  .managedList-tabs {
    background: #a2a2a2;
    color: black;
  }
  .managedList-tabs button.MuiButtonBase-root.MuiTab-root.Mui-selected {
    background: #394150;
    color: white;
  }
  .managedList-tabs button.MuiButtonBase-root.MuiTab-root {
    color: black;
  }
  .customAccordion {
    background: #394150;
    color: white;
  }
  .customAccordion .accordionHeader span.flex-1 {
    color: white;
  }

  .iconBlack {
    svg * {
      stroke: #fff;
    }
  }

  /// table
  .qbs-table {
    .qbs-table-toolbar-sub-container {
      background: #394150 !important;
      color: white !important;
      .rows-count {
        color: white !important;
      }
    }
    .qbs-table-border-wrap {
      background: #1c1f3f;
      .rs-table {
        background: #000;
        .rs-table-row-header {
          &.rs-table-row {
            background: #000;
          }
        }
        .rs-table-row {
          background: #1c1f3f;
        }
        .rs-table-scrollbar {
          background: #fff;
        }
        .rs-table-cell-content {
          background: #1c1f3f;
          color: white;
        }
        .rs-table-cell-header .rs-table-cell-content {
          background: black;
          color: white;
          svg * {
            stroke: #fff;
          }
        }
        .rs-table-row:hover {
          background: black;
          color: white;
          .rs-table-cell-content {
            background: black;
            color: white;
          }
        }
      }
    }
    .qbs-table-custom-pagination {
      background: #394150 !important;
      color: white !important;
      .rows-count {
        color: white !important;
      }
      .qbs-table-pagination-right-block {
        color: white !important;
        svg * {
          stroke: #fff;
        }
      }
      .qbs-table-pagination-flexBox {
        .qbs-table-pagination-text {
          color: white !important;
        }
        .qbs-table-pagination-dropdown {
          background: black;
        }
      }
    }
  }
  ///
  &.customDrawer > .MuiDrawer-paper {
    background: #394150 !important;
  }
}
