@tailwind components;

@layer components {
  .btn {
    @apply rounded-button border flex py-1 items-center justify-center disabled:opacity-70 text-button font-bold disabled:cursor-not-allowed transition;
  }

  .btn-big {
    @apply rounded-button border h-12 flex py-3.5 items-center justify-center disabled:opacity-70 text-s font-bold disabled:cursor-not-allowed transition;
  }

  .btn-primary {
    background-color: #e76458 !important;
    @apply border-button-primary text-white opacity-100 hover:opacity-70 transition-all;
  }

  .btn-secondary {
    @apply border-button-secondary bg-button-secondary text-white opacity-100 hover:opacity-70 transition-all;
  }

  .btn-primary-outlined {
    @apply bg-white dark:bg-black text-button-primary border border-button-primary opacity-100 hover:opacity-70 transition-all;
  }

  .btn-secondary-outlined {
    @apply bg-white text-shadow-light border border-button-secondary opacity-100 hover:opacity-70 transition-all;
  }

  .switch input {
    @apply hidden;
  }

  .switch {
    @apply inline-block w-[36px] h-[18px] translate-y-2/4 relative m-1;
  }

  input:checked + .slider {
    @apply shadow-switch;
  }

  input:checked + .checkbox:before {
    @apply bg-button-primary;
  }

  input:checked + .checkbox {
    @apply shadow-switch;
  }

  input:checked + .radio:before {
    @apply bg-button-primary;
  }

  input:checked + .radio {
    @apply shadow-switch;
  }

  .fab {
    @apply disabled:opacity-70;
  }

  .fab {
    width: 36px;
    height: 36px;

    &.small {
      width: 30px;
      height: 30px;
    }

    &.extra-small {
      width: 18px;
      height: 18px;
    }
  }

  input:checked + .slider:before {
    transform: translateX(18px);
    /*translateX(w-h)*/
    @apply bg-button-primary;
  }

  input:checked + .slider {
    @apply shadow-switch;
  }
}

.radio {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 22px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  height: 22px;
  box-shadow:
    0 0 0 2px #777,
    0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  inset: 0;
  overflow: hidden;
  transition: 0.2s;
}

.radio:before {
  position: absolute;
  content: '';
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #777;
  transition: 0.2s;
}

.checkbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  box-shadow:
    0 0 0 2px #777,
    0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  inset: 0;
  overflow: hidden;
  transition: 0.2s;
}

.checkbox:before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #777;
  transition: 0.2s;
}

.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 36px;
  /*=w*/
  height: 18px;
  /*=h*/
  margin: 4px;
  transform: translateY(50%);
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow:
    0 0 0 2px #777,
    0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #777;
  border-radius: 30px;
  transform: translateX(-18px);
  /*translateX(-(w-h))*/
  transition: 0.2s;
}
/* Genel stil */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 24px;
  margin: 4px;
}

/* Giriş stil */
.toggle-switch .toggle-input {
  display: none;
}

/* Anahtarın stilinin etrafındaki etiketin stil */
.toggle-switch .toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 20px;
  background-color: #c7cbce;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Anahtarın yuvarlak kısmının stil */
.toggle-switch .toggle-label::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

/* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
.toggle-switch .toggle-input:checked + .toggle-label {
  background-color: #e76458;
}

.toggle-switch .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}

/* Light tema */
.toggle-switch.light .toggle-label {
  background-color: #bebebe;
}

.toggle-switch.light .toggle-input:checked + .toggle-label {
  background-color: #9b9b9b;
}

.toggle-switch.light .toggle-input:checked + .toggle-label::before {
  transform: translateX(6px);
}

/* Dark tema */
.toggle-switch.dark .toggle-label {
  background-color: #4b4b4b;
}

.toggle-switch.dark .toggle-input:checked + .toggle-label {
  background-color: #717171;
}

.toggle-switch.dark .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}
