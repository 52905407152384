.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #ff681d;
  box-shadow:
    -24px 0 #ff681d,
    24px 0 #ff681d;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #ff681d;
    box-shadow:
      -24px 0 #ff3d00,
      24px 0 #ff681d;
  }

  66% {
    background: #ff3d00;
    box-shadow:
      -24px 0 #ff681d,
      24px 0 #ff681d;
  }

  100% {
    background: #ff681d;
    box-shadow:
      -24px 0 #ff681d,
      24px 0 #ff3d00;
  }
}
