.filter-dropdown {
  display: inline-block;
  position: relative;
}

.filter-dropdown-content {
  position: absolute;
  min-width: 224px;
  padding: 8px;
  z-index: 999;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.filter-dropdown-content label {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.filter-dropdown button {
  background-color: white;
  border: 1px solid #d6d8dc; /* or any color you prefer for the border */
  border-radius: 4px;
  padding: 6px 10px; /* added some padding for aesthetics */
  cursor: pointer; /* change cursor to pointer when hovering over the button */
  transition: background-color 0.3s ease; /* smooth transition for hover effect */
  height: 36px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
}

.filter-dropdown button:hover {
  background-color: #f7f7f7; /* slightly darker background on hover for feedback */
}

.custom-checkbox-container.is-selected {
  background: #f8f8f8;
  border-radius: 6px;
}
.custom-checkbox-container:hover {
  background: #f8f8f8;
  border-radius: 6px;
}
.dark {
  .filter-dropdown button,
  .filter-dropdown-content {
    background-color: black;
  }
  .custom-checkbox-container:hover,
  .custom-checkbox-container.is-selected {
    background: #394150;
  }
}
